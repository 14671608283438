import React, { useState } from 'react';
import PropTypes from 'prop-types';

const ToggleMore = ({ children }) => {
  const [show, setShow] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    setShow(!show);
  };

  const renderButton = () => (
    <button className="btn btn-sm btn-outline-dark" onClick={(e) => handleClick(e)} type="button">
      {show ? 'Hide' : 'Show more'}
    </button>
  );

  const renderContent = () => <> {children} </>;

  return (
    <>
      {renderButton()}
      {show && renderContent()}
    </>
  );
};

export default ToggleMore;

ToggleMore.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.node, PropTypes.string]).isRequired,
};
