import React from 'react';

import { HelpArticle, SubTitle } from 'components/help/helpArticle';
import Code from 'components/help/codeSnippet';
import Image from 'components/help/helpImage';
import ToggleMore from 'components/help/toggleMore';
import Link from 'components/ui/link';

import screen1 from 'img/help/google_analytics/ga_integration_1.png';
import screen2 from 'img/help/google_analytics/ga_integration_2.png';
import screen3 from 'img/help/google_analytics/ga_integration_3.png';
import screen4 from 'img/help/google_analytics/ga_integration_4.png';
import screen5 from 'img/help/google_analytics/ga_integration_5.png';
import screen6 from 'img/help/google_analytics/ga_integration_6.png';
import screen7 from 'img/help/google_analytics/ga_integration_7.png';
import screen8 from 'img/help/google_analytics/ga_integration_8.png';
import logo from 'img/help/integrations/ga.png';

const Content = () => {
  return (
    <div>
      <SubTitle>What is Google Analytics?</SubTitle>
      <p>
        <strong>Google Analytics tracks visitors on website</strong>. It lets you understand source
        of traffic on your website, conversion rates, and many more.{' '}
      </p>
      <SubTitle className="mt-5">How does this integration work?</SubTitle>
      <p>
        This integration sends links to session replays from LiveSession to your Google Analytics
        tracker.{' '}
        <strong>
          As a result, you can open recordings of a specific user directly from Analytics dashboard
        </strong>
        .
      </p>
      <p>
        Every time a new session will start, our code will send an event to GA tracker with a link
        to session replay.
      </p>
      <SubTitle className="mt-5">How can I install this integration?</SubTitle>
      <span>To install integration, add the code below before your </span>{' '}
      <Code inline>{'</body>'}</Code> <span> closing tag:</span>
      <Code margin="1rem 0" copy>
        {`<script>
if(ga && __ls){
__ls("getSessionURL", function(url, isNew){
    if(isNew){
        ga('send', {
            hitType: 'event',
            eventCategory: 'LiveSession recording',
            eventAction: url,
            nonInteraction: 1
        })
    }
})
}
</script>`}
      </Code>
      <div className="alert alert-primary mb-5 mt-5" role="alert">
        To make this integration works you need to have Google Analytics and LiveSession installed
        on your website. If you haven&apos;t installed our tracking code yet check{' '}
        <Link href="/help/how-to-install-livesession-script/">this article</Link>.
      </div>
      <p className="mt-3">
        Now you can go to &apos;Audience &gt; User Explorer&apos; section and open user profile.
      </p>
      <Image src={screen1} lazy alt="Google Analytics intergration" title="Opening user profile" />
      <p>
        Find event on actions list and expand details. You can also click expand all to make
        browsing data easier.{' '}
      </p>
      <Image
        src={screen2}
        lazy
        alt="Google Analytics intergration"
        title="Find event on actions list and expand details"
      />
      <p>
        Find &apos;LiveSession recording&apos; event on actions list and copy-paste link to your web
        browser.{' '}
      </p>
      <Image
        src={screen3}
        lazy
        alt="Google Analytics intergration"
        title="Copy-paste link to your web browser"
      />
      <SubTitle className="mt-5">
        (Optional) Segment users with LiveSession session replay in Google Analytics
      </SubTitle>
      <p>
        You can define &quot;LiveSession&quot; segment in Google Analytics to search users with our
        event. It can make browsing session replays in GA easier. It&apos;s possible to add your own
        conditions to our segment and search for specific user groups.
      </p>
      <ToggleMore>
        <p className="mt-3">Firstly, click on &quot;Add segment&quot;.</p>
        <Image src={screen4} lazy alt="Google Analytics intergration" title="Add segment" />

        <p>Then click on &quot;New segment&quot;.</p>
        <Image
          src={screen5}
          lazy
          alt="Google Analytics intergration"
          title="Click on new segment"
        />

        <p>Open conditions section.</p>
        <Image
          src={screen6}
          lazy
          alt="Google Analytics intergration"
          title="Open conditions section"
        />

        <p>Now we&apos;ll filter sessions. Find &quot;Event Category&quot; on list.</p>
        <Image
          src={screen7}
          lazy
          alt="Google Analytics intergration"
          title="Find 'Event Category' on list"
        />

        <p>
          Set exactly matches (1). Type in our &quot;Event Category&quot; name, in code specified
          above it&apos;s &quot;LiveSession recording&quot; (2). Then, let&apos;s set up name for
          our new segment - &quot;Users with session replays&quot; (3). Finally, save the segment
          (4). You can add more conditions to filter some specific user groups.
        </p>
        <Image src={screen8} lazy alt="Google Analytics intergration" title="Add filters" />
      </ToggleMore>
    </div>
  );
};

const related = [];

export const frontmatter = {
  metaTitle: 'LiveSession and Google Analytics integration',
  metaDescription: 'Send LiveSession recordings as events to Google Analytics',
  canonical: '/help/google-analytics-integration/',
  logo,
};

const Wrapped = HelpArticle(Content);

export default () => (
  <Wrapped
    related={related}
    section="Integrations"
    title={frontmatter.metaTitle}
    {...frontmatter}
  />
);
